<template>
  <div>
    <h3>{{ isEdit ? "Edit User" : "Add User" }}</h3>
    <div class="space-10"></div>
    <v-row class="my-0">
      <v-col :cols="hideuserName ? '9' : '12'">
        <v-text-field
          v-if="hideuserName"
          v-model.trim="item.email"
          label="Email"
          type="email"
          autocomplete="off"
          required
          outlined
          dense
          :rules="[(v) => !!v || 'Email is required']"
        ></v-text-field>
        <v-text-field
          v-else
          v-model.trim="item.name"
          label="User Name"
          :rules="[(v) => !!v || 'Name is required']"
          required
          outlined
          autocomplete="off"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-n5">
      <v-col :cols="hideuserName ? '9' : '12'">
        <v-text-field
          v-model.trim="item.password_hash"
          :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
          label="Password"
          :type="showPwd ? 'text' : 'password'"
          required
          outlined
          autocomplete="new-password"
          dense
          :rules="pwdRule"
          @click:append="showPwd = !showPwd"
        ></v-text-field
      ></v-col>
      <v-col cols="2" v-if="hideuserName"
        ><v-btn color="success" @click="getRandomPassword">
          Generate Password</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="mt-n10">
      <v-col>
        <v-switch v-model="item.status" inset label="Status"></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="!hideuserName">
      <v-col>
        <v-text-field
          v-model.trim="item.email"
          label="Email"
          type="email"
          autocomplete="nope"
          required
          outlined
          dense
          :rules="[(v) => !!v || 'Email is required']"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          :items="userRoles"
          label="Select User Role"
          v-model="item.user_role"
          item-text="name"
          item-value="id"
          disabled
          outlined
          dense
        >
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UserEdit",
  props: {
    item: Object,
    hideuserName: Boolean,
  },
  data() {
    return {
      valid: true,
      isEdit: false,
      showPwd: false,
      userRoles: [
        { name: "NORMAL_USER", id: 1 },
        { name: "FRANCHISEE", id: 100 },
        { name: "FRANCHISOR", id: 190 },
        { name: "FRANCHISE", id: 200 },
        { name: "UNDERWRITER", id: 300 },
        { name: "BROKER", id: 500 },
        { name: "INSURER", id: 600 },
        { name: "ADMIN", id: 1000 },
      ],
      pwdRule: [
        (v) =>
          v
            ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(
                v
              ) || "Please enter password in correct format"
            : this.isEdit || "Password is required",
      ],
    };
  },
  created() {
    if (this.item.id) {
      this.isEdit = true;
    }
  },
  methods: {
    getRandomPassword() {
      let newPwd =
        Math.random()
          .toString(36)
          .slice(2, 6) +
        "!?=#*$+".charAt(Math.random() * 10) +
        Math.random()
          .toString(6)
          .slice(2, 4) +
        "@" +
        Math.random()
          .toString(36)
          .slice(2, 6)
          .toUpperCase();

      this.$set(this.item, "password_hash", newPwd);
    },
  },
};
</script>
