<template>
  <div>
    <v-card v-if="item" class="px-7">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-switch v-model="item.status" inset label="Status"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.name"
                :label="type + ' name'"
                :rules="name_rules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.abn"
                :label="'ABN'"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="type === 'Insurer'">
            <v-col>
              <v-text-field
                v-model="item.winbeat_ref_code"
                :label="'Winbeat Ref Code'"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-email"
                v-model="item.renewal_email"
                :label="'Renewal Email'"
                :rules="[(v) => !!v || 'Email is required']"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-phone"
                v-model="item.renewal_phone"
                :label="'Renewal Phone'"
                hint="Enter Phone number with country code"
                :rules="[
                  (v) =>
                    !v ||
                    /^[0-9 ()+-]+$/.test(v) ||
                    'Please Enter a Valid Phone Number',
                ]"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-n5">
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-email"
                v-model="item.referral_email"
                :rules="[(v) => !!v || 'Email is required']"
                label="Referral Email"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-phone"
                v-model="item.referral_phone"
                hint="Enter Phone number with country code"
                label="Referral Phone"
                :rules="[
                  (v) =>
                    !v ||
                    /^[0-9 ()+-]+$/.test(v) ||
                    'Please Enter a Valid Phone Number',
                ]"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="space-20"></div>
          <h3>Key Contact</h3>
          <div class="space-10"></div>
          <v-divider class="py-3"></v-divider>
          <v-row class="mt-n5">
            <v-col>
              <!-- <ContactAutoCompleteForm
                :form.sync="item.key_contact"
                :type="type"
                :isAddress="true"
                :isPhone="true"
                :isFax="true"
                :isEmail="true"
                :isWebsite="true"
              /> -->
              <ContactForm
                :form="item.key_contact"
                :ref="`${type}_contact_form`"
                :isValid="isContactValid"
                :type="type"
                :isAddress="true"
                :isPhone="true"
                :isFax="true"
                :isEmail="true"
                :isWebsite="true"
              />
            </v-col>
          </v-row>

          <div class="space-20"></div>
          <v-footer class="paddless pa-7">
            <UserEditForm
              :item="item.user"
              v-if="isDataLoaded && isUserAdmin"
            />
          </v-footer>
          <div class="space-20"></div>

          <v-row class="mx-1 my-2">
            <v-btn
              class="ma-2"
              :loading="loading"
              :disabled="loading"
              color="success"
              @click="validate"
            >
              <v-icon small left>mdi-check</v-icon>
              Save
            </v-btn>
            <v-btn class="ma-2" outlined @click="reset">
              Reset
            </v-btn>
            <v-btn class="ma-2" text color="primary" @click="closeBrokerDialog">
              Cancel
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserEditForm from "@/components/common/UserEditForm";
import { agent_type, crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import ContactForm from "@/components/common/contact-form/ContactForm";
import Contact from "@/models/contact-form";

export default {
  name: "broker-edit",
  props: ["crud_state", "item"],

  components: {
    ContactForm,
    UserEditForm,
  },
  data() {
    return {
      loading: false,
      isContactValid: {},
      valid: true,
      isDataLoaded: false,
      name_rules: [(v) => !!v || "Name is required"],
    };
  },

  created() {
    let contactType = this.item.key_contact.address;
    this.item.user = this.item.user ?? {};
    if (!this.item.user.user_role) {
      this.item.user.user_role = this.item.type_id == 1 ? 600 : 500;
    }

    if (contactType) {
      Object.keys(contactType).forEach((key) => {
        if (
          contactType[key] === null &&
          (key == "country" || key == "state" || key == "city")
        ) {
          contactType[key] = {};
        }
      });
    }
    this.isDataLoaded = true;
  },

  methods: {
    validate() {
      let result = this.$refs.form.validate();
      this.isContactValid[this.type] = this.$refs[
        `${this.type}_contact_form`
      ].$refs[`contact-form`].validate();
      if (result && this.isContactValid[this.type]) {
        this.save();
      }
    },
    closeBrokerDialog() {
      this.$emit(`closeBrokerDialog`);
    },
    reset() {
      this.$refs.form.reset();
    },
    async save() {
      this.loading = true;
      let service = new BaseService("brokers");
      // this.item.key_contact_id = this.item.key_contact?.contact_id;
      this.item.key_contact.address.city_id = this.item.key_contact.address?.city?.id;
      this.item.key_contact.address.state_id = this.item.key_contact.address?.state?.id;
      this.item.key_contact.address.country_id = this.item.key_contact.address?.country?.id;
      // this.item.user = { ...this.$store.state.auth.user };

      let res;
      if (this.crud_state == crud_state.ADD) {
        res = await service.create(this.item);
      } else {
        res = await service.update(this.item);
      }
      this.loading = false;

      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        this.$emit("edit_save", {
          msg: "Saved successfully",
          data: res.data.results,
        });
        // this.item = res.data.results;
      } else {
        this.$emit("edit_error", { msg: "An error occurred, could not save" });
      }
    },

    handleLogout() {
      this.$store.dispatch("auth/logout");
    },
  },

  computed: {
    ...mapGetters("auth", ["userRole"]),

    isUserAdmin() {
      return this.userRole.includes("ADMIN");
    },
    type() {
      return parseInt(this.item.type_id) == agent_type.INSURER
        ? "Insurer"
        : "Broker";
    },
    blankContact() {
      return new Contact();
    },
  },
};
</script>

<style lang="scss" scoped></style>
