<template>
  <div>
    <h1 class="page-title">{{ type }} Manager</h1>

    <template>
      <SimpleTable
        :headers="headers"
        :items="transformed_items"
        :loading="loading"
        :searchLoading="searchLoading"
        :expanded_headers="final_expanded_headers"
        :totalCount="totalCount"
        url_fragment="brokers"
        @hitPagination="fetchNextData"
        @hitSearch="fetchSearchedData"
        @add_item_click="add_item_click"
        @edit_item_click="edit_item_click"
      />
    </template>

    <div>
      <v-dialog v-model="show_edit_form" max-width="750px">
        <v-card v-if="loading">
          <v-skeleton-loader
            v-bind="$attrs"
            type="table-heading, list-item-two-line, image"
          ></v-skeleton-loader>
        </v-card>
        <v-card v-else>
          <v-card-title>{{ edit_form_dialog_title }}</v-card-title>
          <BrokerEdit
            :crud_state="crud_state"
            :item="item_to_edit"
            @edit_save="edit_save"
            @edit_error="edit_error"
            @error="error"
            @closeBrokerDialog="show_edit_form = false"
          />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { agent_type, crud_state } from "@/lib/constants";
import SimpleTable from "@/components/common/SimpleTable.vue";
import BrokerEdit from "@/components/broker/BrokerEdit.vue";
import BaseService from "@/services/base.service.js";
import BrokerService from "@/services/broker.service.js";
import Broker from "@/models/broker.js";
import Contact from "@/models/contact-form";
import Address from "@/models/address-form";

export default {
  name: "broker-insurance",

  props: ["type_id"],

  data() {
    return {
      // for list view
      headers: [
        {
          text: "Name",
          align: "start",
          // filterable: false,
          value: "name",
        },
        { text: "Address", value: "addr" },
        { text: "Renewal Email", value: "renewal_email" },
        { text: "Renewal Phone", value: "renewal_phone" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],

      items: [], // list of all items to show in the table
      totalCount: 0,

      expanded_headers: [
        { text: "Referral Email", value: "referral_email" },
        { text: "Referral Phone", value: "referral_phone" },
      ],

      expanded_headers_insurer: [
        { text: "Formal Policy#", value: "formal_policy_num" },
        { text: "Expiry Type", value: "expiry_type" },
        { text: "Notice Days", value: "notice_days" },
      ],

      // for add new / edit
      item_to_edit: null,

      crud_state: crud_state.LIST, // can be list, edit, addnew
      crud_id: null, // id crud is actioning upon

      // generic
      loading: false,
      searchLoading: false,
      search: "",
      paginations: {},
    };
  },

  methods: {
    async fetchNextData(pagination) {
      this.items = await this.get_items({ type_id: this.type_id, pagination });
    },
    async fetchSearchedData(search) {
      if (search) {
        this.searchLoading = true;
        const limit = this.paginations.itemsPerPage;
        const offset =
          (this.paginations.page - 1) * this.paginations.itemsPerPage + 1;

        // this.loading = true;
        let filters = {
          limit: limit,
          start: offset,
          search,
          type_id: this.type_id,
        };

        try {
          let res = await BrokerService.getBrokersSuggestions(filters);
          this.totalCount = res.data.count;
          this.items = res.data.results;
        } catch (e) {
          console.error(e);
        } finally {
          this.searchLoading = false;
        }
      } else {
        this.items = await this.get_items(this.paginations);
      }
    },
    async get_items({ type_id, pagination = { itemsPerPage: 10, page: 1 } }) {
      this.paginations = { ...pagination };
      const limit = pagination.itemsPerPage;
      const offset = (pagination.page - 1) * pagination.itemsPerPage + 1;

      this.loading = true;
      let service = new BaseService("brokers");
      let filters = { type_id: type_id, limit: limit, start: offset };

      try {
        let res = await service.get_all(filters);
        this.totalCount = res.data.count;
        return res.data.results;
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async get_item(id) {
      if (!id) {
        return null;
      }
      this.loading = true;
      let service = new BaseService("brokers");
      let res = await service.get_one(id);

      res.data.key_contact = res.data.key_contact ?? new Contact();
      res.data.key_contact.contact_id = res.data.key_contact?.id;
      res.data.key_contact.phone = res.data.key_contact.phone ?? {};
      res.data.key_contact.fax = res.data.key_contact.fax ?? {};
      res.data.key_contact.mobile = res.data.key_contact.mobile ?? {};
      res.data.key_contact.address =
        res.data.key_contact.address ?? new Address();
      this.loading = false;
      return res.data;
    },

    async edit_item_click(id) {
      this.logout_if_login_not_valid();
      // pop up edit form
      // this.crud_id = id;
      this.item_to_edit = await this.get_item(id);
      if (this.item_to_edit) {
        this.crud_state = crud_state.EDIT;
        this.show_edit_form = true;
      } else {
        this.edit_error();
      }
    },

    add_item_click() {
      this.logout_if_login_not_valid();
      // pop up edit form
      this.item_to_edit = new Broker();
      this.item_to_edit.status = true;
      this.item_to_edit.type_id = this.type_id;
      // default to common expiry type, for insurers
      // brokers dont have expiry type, so setting to null
      this.item_to_edit.expiry_type =
        this.type_id == agent_type.INSURER ? false : null;
      this.crud_state = crud_state.ADD;
      this.show_edit_form = true;
    },

    async edit_save(/* item */) {
      this.show_edit_form = false;
      this.$store.dispatch("common/setFlashMsg", "Saved successfully");
      // refresh data
      this.items = await this.get_items({ type_id: this.type_id });
    },

    edit_error(payload) {
      this.show_edit_form = false;
      this.$store.dispatch("common/setFlashMsg", payload.msg);
    },

    error(payload) {
      this.show_edit_form = false;
      this.$store.dispatch("common/setFlashMsg", payload.msg);
    },
  },

  computed: {
    type() {
      return parseInt(this.type_id) == agent_type.INSURER
        ? "Insurer"
        : "Broker";
    },

    // transform items to desired values for output in grid
    transformed_items() {
      let transformed_items = this.items.map((item) => {
        item.expiry_type = item.expiry_type ? "Variable" : "Common";
        return item;
      });
      return transformed_items;
    },

    final_expanded_headers() {
      if (this.type_id === agent_type.INSURER) {
        return [...this.expanded_headers, ...this.expanded_headers_insurer];
      }
      return this.expanded_headers;
    },

    show_edit_form: {
      get: function() {
        return (
          this.crud_state == crud_state.ADD ||
          this.crud_state == crud_state.EDIT
        );
      },

      set: function(newValue) {
        if (!newValue) {
          this.crud_state = crud_state.LIST;
        }
      },
    },

    edit_form_dialog_title() {
      return this.crud_state === crud_state.ADD
        ? `Add ${this.type}`
        : `Edit ${this.type}`;
    },
  },

  watch: {
    type_id: async function(newValue) {
      this.items = await this.get_items({ type_id: newValue });
    },
  },

  async mounted() {
    this.items = await this.get_items({ type_id: this.type_id });
  },

  components: { SimpleTable, BrokerEdit },
};
</script>

<style lang="scss" scoped></style>
