import Notification from "./notification-form";

export default class Contact {
  constructor() {
    this.name = "";
    this.person_name = "";
    this.address = {
      name: "",
      city_text: "",
      state: {},
      country: {},
    };
    this.phone = {};
    this.mobile = {};
    this.notification = new Notification();
    this.fax = {};
    this.email = null;
    this.status = null;
    this.website = null;
  }
}
