import Contact from "./contact-form";

export default class Broker {
  constructor() {
    this.id = null;
    this.name = null;
    this.descr = null;
    this.status = null;
    this.type_id = null;
    this.addr = null;
    this.renewal_email = null;
    this.renewal_phone = null;
    this.referral_email = null;
    this.referral_phone = null;
    this.formal_policy_num = null;
    this.expiry_type = null;
    this.notice_days = null;
    this.key_contact = new Contact();
  }
}
