<template>
  <div>
    <h3 v-if="notificationTitle">{{ notificationTitle }}</h3>
    <v-form>
      <v-switch
        hide-details
        class="ml-4"        
        v-model="item.new_renewal_notify"
        inset
        label="Get notification on policy renewals"
      ></v-switch>
      <v-switch
         hide-details
        class="ml-4"
        v-model="item.policy_update_notify"
        inset
        label="Get notification on policy updates"
      ></v-switch>
      <v-switch
         hide-details
        class="ml-4"
        v-model="item.cancellation_notify"
        inset
        label="Get notification on Cancellation"
      ></v-switch>
      <v-switch
         hide-details
        class="ml-4"
        v-model="item.claim_notify"
        inset
        label="Get notification on Claims"
      ></v-switch>
      <v-switch
         hide-details
        class="ml-4"
        v-model="item.lapse_notify"
        inset
        label="Get notification on policy Lapse"
      ></v-switch>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "notification-options",
  props: {
    item: Object,
    notificationTitle: String,
  },
};
</script>
