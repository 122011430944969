<template>
  <div>
    <h2>{{ formTitle }}</h2>
    <v-form v-model="isValid[type]" ref="contact-form" lazy-validation>
      <template>
        <v-text-field
          class="mt-5"
          label="Enter Contact person"
          :rules="[
            (v) =>
              validateName(v) ||
              'Person name should contain first name and last name',
          ]"
          v-model="form.person_name"
          outlined
          dense
          prepend-icon="mdi-account"
        ></v-text-field>
      </template>
      <template v-if="isAddress">
        <v-row>
          <v-col cols="12" lg="6" md="6">
            <v-text-field
              label="Street Address 1"
              outlined
              v-model="form.address.address1"
              dense
              prepend-icon="mdi-card-account-details-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <v-text-field
              label="Street Address 2"
              outlined
              v-model="form.address.address2"
              dense
              prepend-icon="mdi-card-account-details-outline"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" md="6" class="py-0">
            <v-combobox
              class="margin-left-10"
              :items="countries"
              @focus="getAllPlace('countries', 'countries')"
              @update:search-input="
                preparePlace($event, 'country', 'countries')
              "
              :search-input.sync="selectedPlace.country"
              hide-no-data
              clearable
              v-model="form.address.country.name"
              @change="setInItem(`country`, form.address.country.name)"
              @click:clear="form.address.country = {}"
              item-text="name"
              outlined
              autocomplete="nope"
              label="Select Country"
              dense
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" lg="6" md="6" class="py-0">
            <v-combobox
              class="margin-left-10"
              :items="states"
              @focus="getAllPlace('states', 'states')"
              @update:search-input="preparePlace($event, 'state', 'states')"
              :search-input.sync="selectedPlace.state"
              :disabled="form.address.country.name ? false : true"
              @change="setInItem(`state`, form.address.state.name)"
              @click:clear="form.address.state = {}"
              clearable
              v-model="form.address.state.name"
              item-text="name"
              outlined
              label="Select State"
              autocomplete="nope"
              dense
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" md="6" class="py-0">
            <!-- <v-combobox
              class="margin-left-10"
              :items="cities"
              @focus="getAllPlace('cities', 'cities')"
              @update:search-input="preparePlace($event, 'city', 'cities')"
              :search-input.sync="selectedPlace.city"
              :disabled="!form.address.country.name"
              @change="setInItem(`city`, form.address.city.name)"
              @click:clear="form.address.city = {}"
              clearable
              v-model="form.address.city.name"
              item-text="name"
              outlined
              autocomplete="nope"
              label="Select City"
              dense
            >
            </v-combobox> -->
            <v-text-field
              class="margin-left-10"
              label="Enter City/ Suburb Name"
              v-model="form.address.city_text"
              outlined
              :rules="[(v) => !!v || 'City/ Suburb is required']"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" class="py-0">
            <v-text-field
              class="margin-left-10"
              label="Enter Postcode"
              v-model="form.address.post_code"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-if="isPhone">
        <v-row>
          <v-col cols="12" lg="2" md="2" class="py-0">
            <v-combobox
              :items="phoneCodes"
              @focus="getAllPlace('countries', 'phoneCodes')"
              @update:search-input="
                preparePlace($event, 'phone_dial_code', 'phoneCodes')
              "
              :search-input.sync="selectedPlace.phone_dial_code"
              item-value="dial_code"
              clearable
              :return-object="false"
              v-model="form.phone.dial_code"
              item-text="name"
              class="margin-left-10"
              outlined
              autocomplete="nope"
              label="Country"
              dense
            >
              <template v-slot:item="{ item }">
                +{{ item.dial_code }} {{ item.name }}
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" lg="10" md="10" class="py-0">
            <v-text-field
              type="number"
              label="Enter Phone Number"
              v-model="form.phone.num"
              outlined
              dense
              :rules="[(v) => !!v || 'Phone Number is required']"
              prepend-icon="mdi-phone"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-if="isMobile">
        <v-row>
          <v-col cols="12" lg="2" md="2" class="py-0">
            <v-combobox
              :items="mobileCodes"
              @focus="getAllPlace('countries', 'mobileCodes')"
              @update:search-input="
                preparePlace($event, 'mobile_dial_code', 'mobileCodes')
              "
              value="dial_code"
              :return-object="false"
              cache-items
              class="margin-left-10"
              ref="mobile-dial"
              :search-input.sync="selectedPlace.mobile_dial_code"
              item-value="dial_code"
              clearable
              v-model="form.mobile.dial_code"
              item-text="name"
              outlined
              autocomplete="nope"
              label="Country"
              dense
            >
              <template v-slot:item="{ item }">
                +{{ item.dial_code }} {{ item.name }}
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" lg="10" md="10" class="py-0">
            <v-text-field
              type="number"
              label="Enter Mobile Number"
              v-model="form.mobile.num"
              outlined
              dense
              prepend-icon="mdi-phone"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-if="isFax">
        <v-row>
          <v-col cols="12" lg="2" md="2" class="py-0">
            <v-combobox
              :items="faxCodes"
              @focus="getAllPlace('countries', 'faxCodes')"
              @update:search-input="
                preparePlace($event, 'fax_dial_code', 'faxCodes')
              "
              :search-input.sync="selectedPlace.fax_dial_code"
              item-value="dial_code"
              clearable
              :return-object="false"
              v-model="form.fax.dial_code"
              item-text="name"
              outlined
              class="margin-left-10"
              label="Country"
              autocomplete="nope"
              dense
            >
              <template v-slot:item="{ item }">
                +{{ item.dial_code }} {{ item.name }}
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" lg="10" md="10" class="py-0">
            <v-text-field
              type="number"
              label="Enter Fax Number"
              v-model="form.fax.num"
              outlined
              dense
              prepend-icon="mdi-phone"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-if="isEmail">
        <v-text-field
          class="pt-4"
          label="Enter Email Address"
          v-model="form.email"
          :rules="[(v) => !!v || 'Email is required']"
          outlined
          dense
          prepend-icon="mdi-email"
        ></v-text-field>
      </template>
      <template v-if="isWebsite">
        <v-text-field
          label="Enter Website"
          v-model="form.website"
          outlined
          dense
          prepend-icon="mdi-web"
        ></v-text-field>
      </template>
      <template v-if="isWinBEAT">
        <v-text-field
          class="margin-left-10"
          label="Enter WinBEAT AM code"
          v-model="form.wb_am_code"
          outlined
          dense
        ></v-text-field>
      </template>
      <template v-if="isToBeNotified">
        <NotificationOptions
          v-if="isNotificationLoaded"
          :item="form.notification"
          :notificationTitle="'Notification Options'"
        />
      </template>
    </v-form>
  </div>
</template>

<script>
import ContactService from "@/services/contact.service.js";
import NotificationOptions from "../NotificationOptions";
import Notification from "@/models/notification-form";
import { debounce } from "@/helpers/basic_helper";

export default {
  name: "ContactForm",
  components: {
    NotificationOptions,
  },
  data() {
    return {
      selectedPlace: {},
      countries: [],
      states: [],
      cities: [],
      phoneCodes: [],
      faxCodes: [],
      mobileCodes: [],
      isNotificationLoaded: false,
    };
  },
  created() {
    if (!this.form.notification) {
      this.form.notification = new Notification();
    }

    this.form.notification.contact_id = this.form.id;
    this.isNotificationLoaded = true;
  },
  methods: {
    validateName(name) {
      let newName = name.trim();
      let nameArr = newName.split(" ");

      return nameArr.length > 1;
    },
    setInItem(type, obj) {
      if (obj) {
        this.form.address[type].id = obj.id;
        this.form.address[type].name = obj.name;
      }
    },
    preparePlace(event, type, arr) {
      let placeSearch = event;
      if (placeSearch) {
        placeSearch = placeSearch.replace(/\s/g, "");
        let params;
        switch (type) {
          case "phone_dial_code":
          case "mobile_dial_code":
          case "fax_dial_code":
            params = { name: placeSearch };
            this.getPlace("countries", params, arr);
            break;
          case "country":
            params = { name: placeSearch };
            this.getPlace("countries", params, arr);
            break;
          case "state":
            params = {
              country_id: this.form.address.country.id,
              name: placeSearch,
            };
            this.getPlace("states", params, arr);
            break;
          case "city":
            params = {
              country_id: this.form.address.country.id,
              state_id: this.form.address.state.id,
              name: placeSearch,
            };
            this.getPlace("cities", params, arr);
            break;
        }
      }
    },
    async getAllPlace(type, arr) {
      let service = new ContactService(type);

      let params = {};
      if (type !== "countries") {
        params.country_id = this.form.address.country.id;
        if (type === "cities" && this.form.address.state.id) {
          params.state_id = this.form.address.state.id;
        }

        this.getPlace(type, params, arr);
      } else {
        try {
          let res = await service.get_all(params);
          if (res) {
            this[arr] = res.data.results;
          }
        } catch (error) {
          // console.log(error.response ? error.response : error);
        }
      }
    },
    getPlace: debounce(async function(type, params, arr) {
      let service = new ContactService(type);
      try {
        let res = await service.get_one(params);
        if (res) {
          this[arr] = res.data.results;
        }
      } catch (error) {
        // console.log(error.response ? error.response : error);
      }
    }, 500),
  },
  props: {
    formTitle: String,
    isAddress: Boolean,
    isToBeNotified: Boolean,
    isPhone: Boolean,
    isFax: Boolean,
    isMobile: Boolean,
    isEmail: Boolean,
    isWebsite: Boolean,
    isWinBEAT: Boolean,
    form: Object,
    type: String,
    isValid: Object,
  },
};
</script>

<style scoped>
.margin-left-10 {
  margin-left: 2rem;
}
</style>
