import axios from "axios";
import authHeader from "./auth-header";

export default class Contact {
  constructor(place) {
    this.url = this.url = process.env.VUE_APP_API_BASE_URL + "/" + place;
    this.place = place;
  }

  get_all(params) {
    return axios.get(this.url, {
      headers: authHeader(this.token),
      params: params,
    });
  }

  get_one(params) {
    return axios.get(this.url + "/suggestions", {
      headers: authHeader(this.token),
      params: params,
    });
  }

  create(obj, params = "") {
    if (params) {
      params = "?" + new URLSearchParams(params);
    }

    try {
      return axios.post(this.url + params, obj, { headers: authHeader() });
    } catch (err) {
      return err.response;
    }
  }

  async update(obj) {
    try {
      let response = await axios.put(this.url + `/${obj.id}`, obj, {
        headers: authHeader(),
      });
      return response;
    } catch (error) {
      return error.response ? error.response : error;
    }
  }
}
